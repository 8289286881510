<template>
  <details-card v-if="missingData.length !== 0" :class="($vuetify.breakpoint.smAndDown) ? 'sm' : ''">
    <template v-slot:title>
      {{ $t("completeYourProfile") }}
    </template>
    <template v-slot:content>
      <v-alert dense class="fill-height p-4">
        <p class="description main_text_color--text">
          {{ (currentUser && currentUser.type_user == $options.USER_TYPE_CLIENT) ? $t('missingInformationClient') : $t('missingInformation') }}
        </p>
        <div class="completion-content">
          <v-progress-circular
            class="completion-progress"
            :size="268"
            :width="30"
            color="primary"
            :value="getPercentage"
          >
            <span class="percentage font-weight-bold dark_blue--text"
              >{{ getPercentage }} %
            </span>
          </v-progress-circular>

          <div>
            <div
              v-for="(item, i) in missingData"
              :key="i"
              :color="item.color"
              small
            >
              <div class="d-flex porcentage-list">
                <div :class="['d-flex', item.description ? 'align-items-end' : 'align-items-center']">
                  <v-switch v-model="item.complete" inset color="primary" :disabled="item.missing" readonly></v-switch>
                  <div>
                    <span :class="[item.color !== 'grey' ? 'main_text_color--text' : 'gray_stroke--text', 'item-porcentage']">{{ item.text }}</span><br>
                    <span class="hint_text_color--text item-porcentage-description">{{ item.description }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-alert>
    </template>
  </details-card>
</template>
<script>
import {
  USER_TYPE_PROSKER,
  USER_TYPE_CLIENT,
  PROFILE_IMG,
  BANNER_IMG,
  FULLNAME,
  CATEGORY,
  MERCADOPAGO_ACCOUNT,
  SKILL,
  ADDRESS
} from '@/misc/constants';
import userDataMixin from '@/mixins/userDataMixin';
import DetailsCard from '@/components/socialvue/cards/DetailsCard.vue';

export default {
  name: 'ProfileCompletion',
  mixins: [userDataMixin],
  components: {
    DetailsCard
  },
  data () {
    return {
      missingData: [],
      imagePath: require('@/assets/images/page-img/green-bgr.png')
    };
  },
  watch: {
    getMissingData: function (value) {
      if (value) {
        this.fillMissingData();
      }
    }
  },
  computed: {
    getMissingData () {
      return this.$store.getters['auth/missingData'];
    },
    getPercentage () {
      return this.$store.getters['auth/percentage'];
    },
    currentUser () {
      return this.$store.getters['auth/currentUser'];
    }
  },
  created () {
    this.setImportedConstants();
    this.fillMissingData();
  },
  methods: {
    setImportedConstants () {
      this.$options.USER_TYPE_CLIENT = USER_TYPE_CLIENT;
      this.$options.USER_TYPE_PROSKER = USER_TYPE_PROSKER;
      this.$options.PROFILE_IMG = PROFILE_IMG;
      this.$options.BANNER_IMG = BANNER_IMG;
      this.$options.FULLNAME = FULLNAME;
      this.$options.CATEGORY = CATEGORY;
      this.$options.MERCADOPAGO_ACCOUNT = MERCADOPAGO_ACCOUNT;
      this.$options.SKILL = SKILL;
      this.$options.ADDRESS = ADDRESS;
    },
    fillMissingData () {
      if (this.currentUser.type_user >= this.$options.USER_TYPE_PROSKER) {
        this.missingData = [
          {
            text: this.$t('addFullNameToUser'),
            description: this.$t('requireItem'),
            color: this.getMissingData.includes(this.$options.FULLNAME) ? 'grey' : 'green lighten-3',
            missing: this.getMissingData.includes(this.$options.FULLNAME),
            complete: !this.getMissingData.includes(this.$options.FULLNAME)
          },
          {
          text: this.$t('pickUserImage'),
            description: this.$t('requireItem'),
            color: this.getMissingData.includes(this.$options.PROFILE_IMG) ? 'grey' : 'green lighten-3',
            missing: this.getMissingData.includes(this.$options.PROFILE_IMG),
            complete: !this.getMissingData.includes(this.$options.PROFILE_IMG)
          },
          {
            text: this.$t('addCategory'),
            description: this.$t('requireItem'),
            color: this.getMissingData.includes(this.$options.CATEGORY) ? 'grey' : 'green lighten-3',
            missing: this.getMissingData.includes(this.$options.CATEGORY),
            complete: !this.getMissingData.includes(this.$options.CATEGORY)
          },
          {
            text: this.$t('pickBannerImage'),
            color: this.getMissingData.includes(this.$options.BANNER_IMG) ? 'grey' : 'green lighten-3',
            missing: this.getMissingData.includes(this.$options.BANNER_IMG),
            complete: !this.getMissingData.includes(this.$options.BANNER_IMG)
          },
          {
            text: this.$t('associateAccount'),
            description: this.$t('associateAccountDescription'),
            color: this.getMissingData.includes(this.$options.MERCADOPAGO_ACCOUNT) ? 'grey' : 'green lighten-3',
            missing: this.getMissingData.includes(this.$options.MERCADOPAGO_ACCOUNT),
            complete: !this.getMissingData.includes(this.$options.MERCADOPAGO_ACCOUNT)
          },
          {
            text: this.$t('AddSkillToUser'),
            color: this.getMissingData.includes(this.$options.SKILL) ? 'grey' : 'green lighten-3',
            missing: this.getMissingData.includes(this.$options.SKILL),
            complete: !this.getMissingData.includes(this.$options.SKILL)
          },
          {
            text: this.$t('createAddress'),
            color: this.getMissingData.includes(this.$options.ADDRESS) ? 'grey' : 'green lighten-3',
            missing: this.getMissingData.includes(this.$options.ADDRESS),
            complete: !this.getMissingData.includes(this.$options.ADDRESS)
          }
        ];
        this.showMPButton = !this.missingData[this.$options.MERCADOPAGO_ACCOUNT].missing;
      } else {
        this.missingData = [
          {
            text: this.$t('addFullNameToUser'),
            description: this.$t('requireItem'),
            color: this.getMissingData.includes(this.$options.FULLNAME) ? 'grey' : 'green lighten-3',
            missing: this.getMissingData.includes(this.$options.FULLNAME),
            complete: !this.getMissingData.includes(this.$options.FULLNAME)
          },
          {
          text: this.$t('pickUserImage'),
            description: this.$t('requireItem'),
            color: this.getMissingData.includes(this.$options.BANNER_IMG) ? 'grey' : 'green lighten-3',
            missing: this.getMissingData.includes(this.$options.BANNER_IMG),
            complete: !this.getMissingData.includes(this.$options.BANNER_IMG)
          }
        ];
      }
    }
  },
  mounted () {
    this.trackUserData('load_content', {
      pageType: 'my_account',
      pageSubType: 'detail',
      informationRate: this.getPercentage
    });
  }
};
</script>
<style scoped>
  .porcentage-list {
    min-height: 50px;
  }

  .item-porcentage {
    font-size: 15px;
    font-weight: 600;
  }

  .item-porcentage-description {
    font-size: 14px;
  }

  .completion-content {
    display: flex;
    flex-direction: row;
    gap: 32px;
    margin-top: 56px;
  }

  .percentage {
    font-size: 40px;
    font-weight: 700;
  }
</style>
<style lang="scss" scoped>
  .sm {
    .completion-content {
      flex-direction: column;
      gap: 32px;
    }

    .completion-progress {
      align-self: center;
      order: 2;
    }
  }
</style>
