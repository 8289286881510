<template>
  <v-dialog
    content-class="dialog-rounded"
    v-model="showAdd"
    width="500"
    persistent
    scrollable
    @keydown.esc="onClose"
    @keydown.enter="save"
    :retain-focus="false"
  >
    <template v-slot:activator="{ on }">
      <general-button @click="onNewClicked" :action="on.click" small>
        <prosk-icon icon="edit-black" /> {{ $t("createJob") }}
      </general-button>
    </template>

    <details-card scrollable>
      <v-card-title class="text-h5 bg-prosk-secondary white--text">
        <prosk-title class="headline">
          {{ $t(isEdit ? "editService" : "createService") }}
        </prosk-title>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <custom-text-field
                :label="$t('title')"
                v-model="title"
                :hide-details="titleErrors.length === 0"
                :error-count="titleErrors.length"
                :error-messages="titleErrors"
              ></custom-text-field>
            </v-col>
            <v-col cols="12">
              <custom-text-field
                textarea
                rows="1"
                row-height="15"
                auto-grow
                :disabled="item.isClient"
                :hide-details="descriptionErrors.length === 0"
                :error-count="descriptionErrors.length"
                :error-messages="descriptionErrors"
                :label="$t('description')"
                v-model="description"
              />
            </v-col>
            <v-col cols="12">
              <custom-text-field
                type="number"
                :label="$t('price')"
                :disabled="item.isClient"
                min="0"
                :hide-details="priceErrors.length === 0"
                :error-count="priceErrors.length"
                :error-messages="priceErrors"
                v-model="price"
              ></custom-text-field>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                v-model="schedule_required"
                :label="$t('requireSchedule')"
                color="#59D79D"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="dialog-actions">
        <v-spacer></v-spacer>
        <general-button
          :action="onClose"
          :message="$t('cancel')"
          outlined
          v-if="!item.isClient || isAdmin"
        />
        <general-button
          :action="save"
          :loading="loading"
          :message="$t('save')"
          primary
          v-if="!item.isClient"
        />
        <general-button
          :action="saveReview"
          :loading="loadingReview"
          :message="$t('save')"
          primary
          v-if="item.isClient && item.status === 70"
        />
      </v-card-actions>
    </details-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import GeneralButton from '@/components/socialvue/buttons/GeneralButton.vue';
import getStatusJobsName from '@/components/socialvue/mixins/getStatusJobsName';
import userDataMixin from '@/mixins/userDataMixin';
import ProskIcon from '@/icons/ProskIcon.vue';
import DetailsCard from '../cards/DetailsCard.vue';
import ProskTitle from '../markup/ProskTitle.vue';
import CustomTextField from '../customTextField/CustomTextField.vue';

export default {
  name: 'ServiceDialog',
  components: {
    CustomTextField,
    GeneralButton,
    ProskIcon,
    DetailsCard,
    ProskTitle
  },
  props: [
    'showAdd',
    'onClose',
    'item',
    'onAdded',
    'newItem',
    'selectedUser',
    'isAdmin',
    'showAlert'
  ],
  mixins: [errorDialog, getStatusJobsName, userDataMixin],
  data () {
    return {
      client: null,
      id: null,
      errors: null,
      titleErrors: [],
      descriptionErrors: [],
      priceErrors: [],
      price: 0,
      isLoading: false,
      description: null,
      title: null,
      loading: false,
      schedule_required: false
    };
  },
  watch: {
    showAdd: function (isOpen) {
      if (isOpen && this.item && this.item.isEdit) {
        this.id = this.item.id;
        this.price = this.item.price;
        this.description = this.item.description;
        this.title = this.item.title;
        this.schedule_required = this.item.schedule_required;
        this.isEdit = true;
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  methods: {
    onNewClicked () {
      this.newItem();
      this.resetErrors();
      this.reset();
    },
    reset () {
      this.price = 0;
      this.description = null;
      this.title = null;
      this.schedule_required = false;
    },
    resetErrors () {
      this.errors = null;
      this.titleErrors = [];
      this.descriptionErrors = [];
      this.priceErrors = [];
    },
    save () {
      this.resetErrors();

      let error = false;

      if (this.price < 0) {
        error = true;
        this.priceErrors.push(this.$t('onlyIntegerPrices'));
      }
      if (!this.title) {
        error = true;
        this.titleErrors.push(this.$t('fieldTitleRequired'));
      }
      if (!this.description) {
        error = true;
        this.descriptionErrors.push(this.$t('fieldDescriptionRequired'));
      }
      if (this.price < 151) {
        error = true;
        this.priceErrors.push(this.$t('priceMustBeBiggerThan150'));
      }
      const url = `${process.env.VUE_APP_BASE_URL}/api/services`;
      if (!error) {
        const formData = {
          user_id: this.currentUser.id,
          name: this.title,
          description: this.description,
          price: this.price,
          schedule_required: this.schedule_required,
          payment_required: this.price > 0
        };

        if (!this.item.isEdit) {
          this.loading = true;
          axios
            .post(url, formData)
            .then((response) => {
              this.onClose();
              this.onAdded();
              if (this.showAlert) {
                this.$swal.fire({
                  title: this.$t('awesome'),
                  text: this.$t('serviceCreatedSuccessfully'),
                  confirmButtonColor: '#19AD93'
                });
              }
              this.reset();
              this.resetErrors();
            })
            .catch((error) => {
              this.showError(error);
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.loading = true;
          axios
            .put(`${url}/${this.item.id}`, formData)
            .then(() => {
              this.loading = false;
              this.onClose();
              this.onAdded();
              this.reset();
              this.resetErrors();
            })
            .catch((error) => {
              this.loading = false;
              this.showError(error);
            });
        }
      }
    }
  }
};
</script>
<style scoped>
  h3.headline {
    margin: 0;
    padding: 0 9px;
  }
</style>
