<template>
  <v-row class="services-container" v-if="!loading">
    <template v-if="services.length > 0">
      <v-col cols="12" md="6" v-for="service in services" :key="service.id">
        <user-service-card-item
          :data="service"
          @buyService="onBuyServiceHandler"
        />
      </v-col>
    </template>
    <template v-else>
      <v-col cols="12" md="6">
        {{  $t('noServicesAvailable') }}
      </v-col>
    </template>
  </v-row>
  <v-row class="services-container" v-else>
    <v-col cols="12" md="6" v-for="index in 4" :key="index">
      <v-skeleton-loader
        elevation="0"
        type="article, actions">
      </v-skeleton-loader>
    </v-col>
  </v-row>
</template>
<script>
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import UserServiceCardItem from './UserServiceCardItem.vue';
import axios from 'axios';

export default {
  name: 'UserServiceCards',
  components: {
    UserServiceCardItem
  },
  props: {
    onBuyService: {
      type: Function,
      required: true
    },
    userId: {
      type: String,
      required: true
    }
  },
  mixins: [errorDialog],
  data () {
    return {
      loading: false,
      services: []
    };
  },
  created () {
    this.getServices();
  },
  methods: {
    debug (...args) {
      console.log(...args)
    },
    onBuyServiceHandler (service) {
      this.onBuyService(service);
    },
    getServices () {
      this.loading = true;
      const url = `${process.env.VUE_APP_BASE_URL}/api/services?filters=user_id=${this.userId}`;
      axios.get(url)
        .then(response => {
          this.services = response.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatPrice (price) {
      return `$${price.toFixed(2)}`;
    }
  }
};
</script>
