<template>
  <v-main class="container-prosker">
    <cancellation-reason-dialog
        :on-close="onCancelClose"
        :on-added="onAddCallback"
        :show-cancellation="showCancellationDialog"
        :id-cancel="itemIdCancel"
    />
    <reject-warning
        :confirm-reject="rejectJob"
        :on-close="onRejectClose"
        :show-reject="showReject"
        :loading="loadingReject"
    />
    <help-dialog
        :on-close="onHelpClose"
        :on-added="onAddCallback"
        :show-help="showHelpDialog"
        :id-job="itemIdJob"
        :is-prosker="isProsker"
    />
    <alert :message="$t('thereAreJobsThatNeedReview')" v-if="this.count !== 0"/>

    <div class="title-container">
      <v-tabs
        width="100%"
        v-model="tabIndex"
        show-arrows
        slider-color="secondary"
        class="service-tabs"
        background-color="transparent"
      >
        <v-tab>
          <prosk-title no-color>
            {{ isMyServicesData ? $t('myJobs') : $t('services') }}
          </prosk-title>
        </v-tab>
        <v-tab v-if="isMyServicesData">
          <prosk-title no-color>
            {{ $t('listedServices') }}
          </prosk-title>
        </v-tab>
      </v-tabs>

      <div class="service-mobile">
        <jobs-dialog
          v-if="tabIndex == 0"
          :item="editedItem"
          :on-close="onAddClose"
          :new-item="newItem"
          :show-add="showAdd"
          :on-added="onAddCallback"
          :showJobs="showJobs"
        />
        <service-dialog
          v-if="tabIndex == 1"
          :item="editedItem"
          :on-close="onAddClose"
          :new-item="newItem"
          :show-add="showAdd"
          :on-added="onAddServiceCallback"
        />
      </div>
    </div>

    <v-data-table
      v-if="tabIndex == 0"
      :options.sync="pagination"
      :server-items-length="pagination.totalItems"
      :loading="loadingTable"
      :search="search"
      :headers="showHeaders"
      :footer-props="{itemsPerPageText: $t('rowsPerPage')}"
      :items="servicesData"
      item-key="id"
      class="services-table"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <custom-text-field
            class="search-box"
            :placeholder="$t('search')"
            icon="mdi-magnify"
            flat
            full-width
            hide-details
            v-model="search"
            color="main_text_color"
          />

          <!-- <div>
            {{ $t('rowsPerPage') }}
            <custom-select-field
              :items="[10, 20, 30]"
              flat
              :value.sync="pagination.itemsPerPage"
              color="main_text_color"
            />
          </div> -->
        </v-toolbar>
      </template>

      <template v-slot:no-data>
        <v-alert :value="true" color="" icon="">
          <span class="">
            {{ $t("noData") }}
          </span>
        </v-alert>
      </template>

      <template v-slot:no-results>
        <v-alert :value="true" color="" icon="">
          <span>
            {{ $t('yourSearchFor') }} "{{ search }}" {{ $t('foundNoResults') }}
          </span>
        </v-alert>
      </template>

      <template v-slot:item.client_user="{ item }">
        <span class="caption mobile-font">{{ item.client_user.first_name }} {{ item.client_user.last_name }}</span>
      </template>
      <template v-slot:item.title="{ item }">
        <span class="caption mobile-font">{{ item.title }}</span>
      </template>
      <template v-slot:item.prosker="{ item }" >
        <span class="caption mobile-font">{{ item.prosker_user.first_name }} {{ item.prosker_user.last_name }}</span>
      </template>
      <template v-slot:item.start_date="{ item }">
        <span class="caption mobile-font">{{ getDateFormat(item.start_date) }}</span>
      </template>
      <template v-slot:item.end_date="{ item }">
        <span class="caption mobile-font">{{ getDateFormat(item.end_date) }}</span>
      </template>
      <template v-slot:item.price="{ item }">
        <money-format
          :value="item.price"
          :locale="$i18n.locale"
          :currency-code="currencyCode"
          :supplemental-precision="0"
        />
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip class="status-chip" :color="getColorClass(item.status)">
          {{ getStatusName(item.status) }}
        </v-chip>
      </template>
      <template v-slot:item.description="{ item }">
        <div v-html="item.description" class="caption mobile-font"></div>
      </template>
      <template v-slot:item.action="{ item }">
        <tooltip-button
            :icon="'mdi-play'"
            :action="jobInProgress"
            :item="item"
            :color="'primary'"
            :message="'start'"
            v-if="item.status === $options.JOB_STATUS_READY_TO_EXECUTE && isMyServicesData"
        ></tooltip-button>
        <tooltip-button
            :icon="'mdi-checkbox-marked-circle'"
            :action="proskerCompleteJob"
            :item="item.id"
            :color="'primary'"
            :message="'completed'"
            v-if="item.status === $options.JOB_STATUS_IN_PROGRESS && isMyServicesData"
        ></tooltip-button>
        <tooltip-button
            :icon="'mdi-cancel'"
            :action="openCancellationDialog"
            :item="item.id"
            :color="'red'"
            :message="'cancel'"
            v-if="item.showCancellation && !isMyServicesData"
        ></tooltip-button>
        <tooltip-button
            :icon="'mdi-close-octagon'"
            :action="openRejectDialog"
            :item="item.id"
            :color="'yellow darken-2'"
            :message="'reject'"
            v-if="item.status === $options.JOB_STATUS_PROSKER_COMPLETE && !isMyServicesData"
        ></tooltip-button>
        <tooltip-button
            :icon="'mdi-cash'"
            :action="payOrder"
            :item="item"
            :color="'success'"
            :message="'pay'"
            v-if="item.payment_required && item.status === $options.JOB_STATUS_NEW && !isMyServicesData"
        ></tooltip-button>
        <tooltip-button
            :icon="'mdi-check-all'"
            :action="completeJob"
            :item="item"
            :color="'primary'"
            :message="'completed'"
            v-if="item.status === $options.JOB_STATUS_PROSKER_COMPLETE && !isMyServicesData"
        ></tooltip-button>
        <tooltip-button
            :icon="'mdi-help'"
            :action="openHelpDialog"
            :item="item.id"
            :color="'title_highlight_color'"
            :message="'help'"
        ></tooltip-button>
      </template>
    </v-data-table>

    <user-services-listing
      v-if="tabIndex == 1"
      ref="servicesListingRef"
      @editItem="editService($event)"
    />
  </v-main>
</template>
<script>
import axios from 'axios';
import moment from 'moment/moment';
import { mapGetters } from 'vuex';
import MoneyFormat from 'vue-money-format';
import _ from 'lodash';
import getStatusJobsName from '@/components/socialvue/mixins/getStatusJobsName';
import JobsDialog from '@/components/socialvue/dialogs/JobsDialog.vue';
import CancellationReasonDialog from '@/components/socialvue/dialogs/CancellationReasonDialog.vue';
import TooltipButton from '@/components/socialvue/buttons/TooltipButton.vue';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import HelpDialog from '@/components/socialvue/dialogs/HelpDialog.vue';
import {
  USER_TYPE_PROSKER,
  JOB_STATUS_NEW,
  JOB_STATUS_REJECTED,
  JOB_STATUS_EXPIRED,
  JOB_STATUS_READY_TO_EXECUTE,
  JOB_STATUS_IN_PROGRESS,
  JOB_STATUS_PROSKER_CANCEL,
  JOB_STATUS_CLIENT_CANCEL,
  JOB_STATUS_PROSKER_COMPLETE,
  JOB_STATUS_CLIENT_REJECTED,
  JOB_STATUS_COMPLETED,
  JOB_STATUS_PROSKER_HELP_REQUEST,
  JOB_STATUS_CLIENT_HELP_REQUEST,
  MERCADOPAGO_ACCOUNT
} from '@/misc/constants';
import RejectWarning from '@/components/socialvue/dialogs/RejectWarning.vue';
import Alert from '@/components/socialvue/alerts/Alert.vue';
import CustomTextField from '@/components/socialvue/customTextField/CustomTextField.vue';
import userDataMixin from '@/mixins/userDataMixin';
import ProskTitle from '@/components/socialvue/markup/ProskTitle.vue';
import UserServicesListing from './UserServicesListing.vue';
import ServiceDialog from '@/components/socialvue/dialogs/ServiceDialog.vue';

export default {
  name: 'UserServices',
  components: {
    Alert,
    CustomTextField,
    ProskTitle,
    RejectWarning,
    HelpDialog,
    TooltipButton,
    CancellationReasonDialog,
    JobsDialog,
    ServiceDialog,
    MoneyFormat,
    UserServicesListing
  },
  props: {
    isMyServices: {
      type: Boolean,
      default: false
    }
  },
  mixins: [errorDialog, getStatusJobsName, userDataMixin],
  data () {
    return {
      servicesData: [],
      showJobs: true,
      missingData: [],
      isProsker: false,
      headersMap: [],
      headers: [],
      count: 0,
      isDirty: false,
      pagination: { totalItems: 0, itemsPerPage: 10, page: 1 },
      loadingTable: false,

      servicesListingData: [],
      loadingTableListing: false,

      search: '',
      showAdd: false,
      isCanceled: false,
      showHelpDialog: false,
      editedItem: {},
      loading: false,
      showReject: false,
      loadingReject: false,
      id: 0,
      status: 20,
      itemIdCancel: 0,
      itemIdJob: 0,
      showCancellationDialog: false,
      isMyServicesData: null,
      currencyCode: '',
      tabIndex: 0,
      preventFirstPaginationEventFlag: 0
    };
  },
  created () {
    if (window.location.pathname.split('/')[1] !== 'perfil') {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      });
    }

    this.currencyCode = process.env.VUE_APP_LOCATION.includes('br') ? 'BRL' : 'UYU';

    this.setIsMyServicesData();
    this.callBack();
  },
  computed: {
    showHeaders () {
      return this.headers.filter(this.checkDataToHide);
    },
    ...mapGetters({
      currentUser: 'auth/currentUser'
    }),
    getMissingData () {
      return this.$store.getters['auth/missingData'];
    }
  },
  watch: {
    'search': {
      handler () {
        this.resetPaginationForSearch(this);
      },
      deep: true
    },
    'pagination': {
      handler () {
        if (!this.isDirty) {
          if (this.preventFirstPaginationEventFlag < 2) { // number because this list are call 2 times on the first render remove that on future
            this.preventFirstPaginationEventFlag++;
          } else {
            this.list();
          }
        }
      },
      deep: true
    },
    $route () {
      this.setIsMyServicesData();
      this.callBack();
    },
    getMissingData: function (value) {
      if (value) {
        this.fillMissingData();
      }
    }
  },
  methods: {
    callBack () {
      this.list();
      this.fillHeadersMap();
      this.fillMissingData();
      this.setImportedConstants();
      this.getReviews();
    },
    setIsMyServicesData () {
      this.isMyServicesData = window.location.pathname.split('/')[2] === 'mis-servicios' || this.isMyServices;
    },
    fillHeadersMap () {
      this.headersMap = [];
      this.headersMap = [
        { text: this.$t('title'), value: 'title', width: '15%' },
        { text: this.$t('description'), value: 'description', width: '15%' },
        { text: this.$t('prosker'), value: 'prosker', width: '15%' },
        { text: this.$t('status'), value: 'status' },
        { text: this.$t('price'), value: 'price' },
        { text: this.$t('client'), value: 'client_user', hideForUrl: 'servicios-contratados' },
        { text: this.$t('startDate'), value: 'start_date', width: '12%' },
        { text: this.$t('endDate'), value: 'end_date', width: '12%' },
        { text: this.$t('support'), value: 'action', sortable: false, width: '110px' }
      ];

      this.headers = Object.values(this.headersMap);
    },
    checkDataToHide (data) {
      if (this.isMyServicesData) {
        if (data.text !== this.$t('prosker')) {
          return data;
        }
      } else {
        if (data.hideForUrl !== 'servicios-contratados') {
          return data;
        }
      }
    },
    fillMissingData () {
      this.missingData = [];
      this.showJobs = true;
      this.getMissingData.forEach((missing) => {
        if (missing === this.$options.MERCADOPAGO_ACCOUNT) {
          this.showJobs = false;
        }
      });
    },
    jobInProgress (item) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/jobs/${item.id}`;
      const data = {
        status: this.$options.JOB_STATUS_IN_PROGRESS
      };
      axios.patch(url, data).then(() => {
        this.list();
      }).catch((error) => {
        this.showError(error);
      });
    },
    proskerCompleteJob (id) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/jobs/${id}`;
      const data = {
        status: this.$options.JOB_STATUS_PROSKER_COMPLETE
      };
      axios.patch(url, data).then(() => {
        this.list();
      }).catch((error) => {
        this.showError(error);
      });
    },
    rejectJob () {
      this.loadingReject = true;
      const url = `${process.env.VUE_APP_BASE_URL}/api/jobs/${this.itemIdCancel}`;
      const data = {
        status: this.$options.JOB_STATUS_CLIENT_REJECTED
      };
      axios.patch(url, data).then(() => {
        this.loadingReject = false;
        this.list();
      }).catch((error) => {
        this.loadingReject = false;
        this.showError(error);
      });
      this.onRejectClose();
    },
    onRejectClose () {
      this.showReject = false;
    },
    setImportedConstants () {
      this.$options.JOB_STATUS_NEW = JOB_STATUS_NEW;
      this.$options.JOB_STATUS_REJECTED = JOB_STATUS_REJECTED;
      this.$options.JOB_STATUS_EXPIRED = JOB_STATUS_EXPIRED;
      this.$options.JOB_STATUS_READY_TO_EXECUTE = JOB_STATUS_READY_TO_EXECUTE;
      this.$options.JOB_STATUS_IN_PROGRESS = JOB_STATUS_IN_PROGRESS;
      this.$options.JOB_STATUS_PROSKER_CANCEL = JOB_STATUS_PROSKER_CANCEL;
      this.$options.JOB_STATUS_CLIENT_CANCEL = JOB_STATUS_CLIENT_CANCEL;
      this.$options.JOB_STATUS_PROSKER_COMPLETE = JOB_STATUS_PROSKER_COMPLETE;
      this.$options.JOB_STATUS_CLIENT_REJECTED = JOB_STATUS_CLIENT_REJECTED;
      this.$options.JOB_STATUS_COMPLETED = JOB_STATUS_COMPLETED;
      this.$options.JOB_STATUS_PROSKER_HELP_REQUEST = JOB_STATUS_PROSKER_HELP_REQUEST;
      this.$options.JOB_STATUS_CLIENT_HELP_REQUEST = JOB_STATUS_CLIENT_HELP_REQUEST;
      this.$options.USER_TYPE_PROSKER = USER_TYPE_PROSKER;
      this.$options.MERCADOPAGO_ACCOUNT = MERCADOPAGO_ACCOUNT;
    },
    resetPagination () {
      if (!this.isDirty) {
        this.pagination = { totalItems: 0, itemsPerPage: 10, page: 1, sortBy: ['id'] };
      }
    },
    resetPaginationForSearch: _.debounce((self) => {
      if (!self.isDirty) {
        self.resetPagination();
      }
    }, 500),
    openCancellationDialog (id) {
      this.showCancellationDialog = true;
      this.itemIdCancel = id;
    },
    openHelpDialog (id) {
      this.isProsker = this.isMyServicesData;
      this.showHelpDialog = true;
      this.itemIdJob = id;
    },
    openRejectDialog (id) {
      this.showReject = true;
      this.itemIdCancel = id;
    },
    onCancelClose () {
      this.showCancellationDialog = false;
    },
    onHelpClose () {
      this.showHelpDialog = false;
    },
    getReviews () {
      if (!this.currentUser) {
        return;
      }

      const url = `${process.env.VUE_APP_BASE_URL}/api/reviews?filters=jobs.client_user_id=${this.currentUser.id}|`;
      axios.get(url).then((response) => {
        response.data.data.forEach((review) => {
          if (review.content === '' || review.rating === 0) {
            this.count++;
          }
        });
      });
    },
    list () {
      if (!this.currentUser) {
        return;
      }

      console.log('list', this.pagination);

      this.isDirty = true;
      this.servicesData = [];
      this.loadingTable = true;
      const userField = this.isMyServicesData ? `prosker_user_id` : `client_user_id`;
      const servicesUrl = `${process.env.VUE_APP_BASE_URL}/api/jobs?search=${this.search}
      &per_page=${this.pagination.itemsPerPage}&page=${this.pagination.page}&sortBy=created_at,desc&filters=${userField}=${this.currentUser.id}`;

      axios.get(servicesUrl).then((response) => {
        this.isDirty = false;
        if (this.isMyServicesData) {
          this.sendDataLayerInfoFirstLoad(response.data.total, 'services');
          response.data.data.forEach((service) => {
            const originalStartDate = new Date(service.start_date);
            const currentDate = new Date();
            const difference = originalStartDate - currentDate;
            const daysPassed = moment(moment.utc(difference)).format('D');
            service.showCancellation = daysPassed > 1;
            this.servicesData.push(service);
          });
        } else {
          this.servicesData = response.data.data;
          this.sendDataLayerInfoFirstLoad(response.data.total, 'my_orders');
          this.servicesData.forEach((service) => {
            const originalStartDate = new Date(service.start_date);
            const currentDate = new Date();
            const difference = originalStartDate - currentDate;
            const daysPassed = moment(moment.utc(difference)).format('D');

            if (!service.payment_required && difference > 0 && daysPassed > 1) {
              service.showCancellation = true;
            }

            if (service.payment_required && difference > 0 && daysPassed > 1 && service.status === JOB_STATUS_NEW) {
              service.showCancellation = true;
            }

            if (service.status === this.$options.JOB_STATUS_CLIENT_CANCEL || service.status === this.$options.JOB_STATUS_PROSKER_CANCEL) {
              service.showCancellation = false;
            }
          });
        }
        this.pagination.totalItems = response.data.total;
        this.loadingTable = false;
      }).catch((error) => {
        this.isDirty = false;
        this.loadingTable = false;
        this.showError(error);
      });
    },
    payOrder (job) {
      this.$router.push({
        name: 'service-checkout',
        params: { serviceId: job.id }
      });
    },
    newItem () {
      this.reset();
      this.showAdd = true;
      this.editedItem.isEdit = false;
      this.editedItem.isClient = !this.isMyServicesData;
    },
    onAddCallback () {
      this.list();
    },
    onAddServiceCallback () {
      this.$refs.servicesListingRef.list();
    },
    onAddClose () {
      this.reset();
      this.editedItem = {};
      this.showAdd = false;
      this.editedItem.isEdit = false;
      this.editedItem.isClient = false;
    },
    getDateFormat (date) {
      const originalDate = new Date(date);
      return moment(moment.utc(originalDate)).format('DD-MM-YYYY');
    },
    reset () {
      this.editedItem = {};
      this.editedItem.client = '';
      this.editedItem.review = '';
      this.editedItem.end_date = '';
      this.editedItem.start_date = '';
      this.editedItem.price = '';
      this.editedItem.end_date_format = '';
      this.editedItem.start_date_format = '';
      this.editedItem.description = '';
      this.editedItem.title = '';
      this.editedItem.payment_required = false;
    },
    completeJob (item) {
      this.editedItem = item;
      this.editedItem.isEdit = true;
      this.editedItem.isClient = true;
      this.showAdd = true;
    },
    editService (item) {
      this.editedItem = item;
      this.editedItem.title = item.name;
      this.editedItem.isEdit = true;
      this.showAdd = true;
    },
    sendDataLayerInfoFirstLoad (totalcontents, pageSubType) {
      this.trackUserData('load_content', {
        pageType: 'my_account',
        pageSubType: pageSubType,
        [(pageSubType) === 'services' ? 'servicesNumber' : 'ordersNumber']: totalcontents
      });
    },
    sendDataLayerServicesInfo () {
      this.trackUserData('load_content', {
      pageType: 'my_account',
      pageSubType: 'services',
      servicesNumber: this.pagination.totalItems
    });
    },
    sendDataLayerOrdersInfo () {
      this.trackUserData('load_content', {
        pageType: 'my_account',
        pageSubType: 'orders',
        ordersNumber: this.pagination.totalItems
      });
    }
  }
};
</script>
<style scoped>
  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 24px;
  }

  .title-container :deep(.prosk-title),
  .service-tabs :deep(.v-tab .prosk-title) {
    margin-bottom: 0;
  }

  .service-tabs :deep(.v-tab:not(.v-tab--active .prosk-title)) {
    color: #606A76 !important;
  }

  .service-tabs :deep(.v-tab.v-tab--active .prosk-title) {
    color: #3F44A6 !important;
  }

  .search-box {
    max-width: 550px;
  }

  .services-table {
    border-radius: 32px;
    overflow: hidden;
    padding: 40px 32px;
  }

  .services-table >>> .v-data-table__mobile-row__header,
  .services-table >>> th {
    color: #6B71F2 !important;
    font-size: 14px !important;
    font-weight: 700;
  }

  .services-table.v-data-table >>> .v-data-table-header-mobile tr:last-child > th {
    border-bottom: 0;
  }

  .services-table >>> .v-data-table__mobile-table-row {
    margin-top: 32px;
  }

  .services-table >>> .v-data-table__mobile-row:last-child {
    padding-bottom: 32px;
  }

  .service-bellow {
    display: none;
  }

  .status-chip {
    padding: 0 16px;
    height: 40px;
  }

  .status-chip.in-progress {
    background-color: #E1F6F9;
    color: #02838F;
  }

  .status-chip.rejected {
    background-color: #FFE2E3;
    color: #EF5350;
  }

  .status-chip.cancel {
    background-color: #FF9598;
    color: #650000;
  }

  .status-chip.complete {
    background-color: #DAFBE5;
    color: #11271A;
  }

  .status-chip.warn {
    background-color: #FF9598;
    color: #650000;
  }

  .status-chip.ready {
    background-color: #B2EBF2;
    color: #006064;
  }

  .status-chip.new {
    background-color: #E1F5FE;
    color: #0D47A1;
  }

  .status-chip.client-help {
    background-color: #90CAF9;
    color: #0D47A1;
  }

  .status-chip.prosker-help {
    background-color: #FFE082;
    color: #FF6F00;
  }

  .status-chip.prosker-cancel {
    background-color: #FFCC80;
    color: #FF6F00;
  }

  @media (max-width: 599px) {
    .mobile-font {
      font-size: .9rem !important;
    }
  }

  @media (max-width: 540px) {
    .title-container {
      flex-direction: column;
      align-items: flex-start;
    }

    .service-mobile {
      width: 100%;
      margin: 24px 0;
    }

    .service-mobile >>> .v-btn {
      height: auto;
      padding: 14px;
      width: 100%;
    }
  }

  @media (max-width: 400px) {
    .title {
      overflow: visible !important;
    }
  }
</style>
